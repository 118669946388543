import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.store("readArticles", JSON.parse(localStorage.getItem("readArticles")) || []);

Alpine.data("readingTracker", (id) => ({
    timer: null,
    id: id,
    parentArticle: null,
    startTimer(id) {
        if (this.timer) return;
        console.log("Timer started!")
        this.timer = setTimeout(() => {
            this.markAsRead(id);
        }, 8000);
    },

    clearTimer() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    },

    markAsRead(id) {
        let readArticles = Alpine.store("readArticles");

        if (!readArticles.includes(id)) {
            if (readArticles.length >= 8) {
                readArticles.shift(); // Remove the oldest entry
            }

            readArticles.push(id);
            localStorage.setItem("readArticles", JSON.stringify(readArticles));
        }

        if (Alpine.store("readArticles").includes(id)) {
            this.$el.classList.add('article-read');
        }
    },


}));
